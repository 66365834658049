.circle {
  /* background-color: #ccc; */
  position: relative;
  width: 360px;
  height: 360px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  /* background: #fff; */
  position: absolute;
  /* left: -10%; */
  height: 80%;
  width: 100%;
  /* border-radius: 50%; */
  /* background: url(../images/3.jpg); */
  background: url(./assets/1.jpg);
  background-size: cover;
  filter: brightness(1.5) contrast(1.5) /*blur()*/;
}

.about-image {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  /* background: url(../images/3.jpg); */
}

.text {
  position: absolute;
  width: 100%;
  height: 100%;
  animation: rotateText 20s linear infinite;
}

.text span {
  position: absolute;
  left: 50%;
  font-size: 1.2rem;
  transform-origin: 0 180px;
  /* font-family: "JetBrains Mono", monospace; */

  /* 180px is half of its parent relative(circle)*/
}

@keyframes rotateText {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
