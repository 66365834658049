.contact {
  height: 80vh;
  position: relative;
}

.contact-bg {
  width: 20px;
  height: 100%;
  background: #ff5757;
  position: absolute;
}

.contact-wrapper {
  display: flex;
  padding: 50px;
}

.contact-left {
  flex: 1;
}

.contact-title {
  font-size: 60px;
  width: 80%;
  color: "#ff5757";
}

.contact-info-item {
  display: flex;
  align-items: center;
  margin: 50px 0;
  font-weight: 300;
  width: 70%;
}

.contact-icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.contact-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-desc {
  font-weight: 200;
}

form {
  margin-top: 20px;
}

input {
  width: 50%;
  height: 50px;
  border: none;
  border-bottom: 1px solid black;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
}

textarea {
  width: 100%;
  margin: 10px 0;
  font-size: 14px;
  padding-left: 10px;
  border: 1px solid black;
}

button {
  border: none;
  padding: 15px;
  background-color: #ff5757;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

/* Media Query */
@media screen and (max-width: 480px) {
  .contact-wrapper {
    flex-direction: column;
    padding: 0 50px;
  }

  .contact-title {
    font-size: 30px;
  }

  .contact-info-item {
    margin: 10px 0;
    width: 100%;
  }

  .contact-desc {
    display: none;
  }

  form {
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
  }

  input {
    width: 35%;
    height: 40px;
    margin: 10px;
    margin-left: 0;
  }

  button {
    margin-top: 10px;
  }
}
