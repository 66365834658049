/* @import url("https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500;600;700"); */
/* @import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap"); */

@font-face {
  font-family: "La Belle Aurore";
  src: url("../src/assets/fonts/LaBelleAurore-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "JetBrains Mono";
  src: url("../src/assets/fonts/JetBrainsMono-VariableFont_wght.ttf") format("truetype"),
    url("../src/assets/fonts/JetBrainsMono-Italic-VariableFont_wght.ttf") format("truetype");
}
@font-face {
  font-family: "Caveat";
  src: url("../src/assets/fonts/Caveat-VariableFont_wght.ttf") format("truetype");
  /* url("../src/assets/fonts/JetBrainsMono-Italic-VariableFont_wght.ttf") format("truetype"); */
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
  text-decoration: none;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "JetBrains Mono", monospace;
  /* font-family: "Caveat", cursive; */
  /* font-family: 'La Belle Aurore', cursive; */
  font-size: 1.6rem;
  /* overflow-x: hidden; */
}

/* Media Query */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...} */

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...}  */
